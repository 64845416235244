import parse from 'html-react-parser'
import React from 'react'
import tw, { styled } from 'twin.macro'
import { AnimatePresence, motion } from 'framer-motion'
import Chevron from '@elements/Chevron'
import { strings } from '../../static/strings'
import listArrow from '@images/icons/arrow-right.svg'

const Section = tw.section`max-w-screen-md mx-auto relative`
const Headline = tw.h2`relative xl:-translate-x-32 mb-8 md:mb-12 px-5 xl:px-0`
const TextWrap = tw(motion.div)`relative w-full mx-auto overflow-hidden`
const Text = styled(motion.div)(({ expanded, expandable }) => [
	expanded === 'false' && expandable === 'true' && tw`after:bg-gradient-to-b after:from-[rgba(255,255,255,0)] after:to-white after:inset-0 after:absolute`,
	tw`px-5 text-sm xl:text-copyXl xl:leading-[30px] relative`,

	`ul {
		br {
			display: none;
		}
		li {
			position: relative;
			padding-left: 1.5rem;

			&::before {
				position: absolute;
				left: 0;
				width: 0.75rem;
				height: 0.75rem;
				background-size: contain;
				top: 0.5rem;
				background-repeat: no-repeat;
				content: '';
				background-image: url(${listArrow});
			}
		}
	}`,
])
const Flex = tw.div`px-5 flex items-center gap-4`
const ShowMore = tw(
	motion.div
)`uppercase relative mx-5 xl:mx-0 pt-2 mt-8 text-sm cursor-pointer text-primary tracking-1 md:tracking-2 before:absolute before:w-24 before:bottom-full before:left-0 before:h-px before:bg-light`

const WpAcfFlowtextBlock = ({ data, locale }) => {
	const { anchor } = data.attributes
	const module = data.modulesFlowtext
	const lang = locale.substr(0, 2)

	const [isExpandable, setIsExpandable] = React.useState(false)
	const [isExpanded, setIsExpanded] = React.useState(false)
	const textRef = React.useRef()

	const checkTextExpandable = React.useCallback(() => {
		const text = textRef.current
		const textHeight = text.scrollHeight
		if (textHeight > 250) setIsExpandable(true)
	}, [textRef])

	React.useEffect(() => {
		checkTextExpandable()
	}, [isExpandable]) // eslint-disable-line react-hooks/exhaustive-deps

	const variants = {
		open: {
			height: 'auto',
			transition: {
				duration: 0.5,
			},
		},
		closed: {
			height: 250,
			transition: {
				duration: 0.5,
			},
		},
	}

	return (
		<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-pb-spacing'>
			{module.headline && <Headline className='headline-50'>{module.headline}</Headline>}
			<TextWrap ref={textRef} animate={isExpanded || !isExpandable ? 'open' : 'closed'} variants={variants}>
				<AnimatePresence>
					{module.text && (
						<Text expanded={`${isExpanded}`} expandable={`${isExpandable}`}>
							{parse(module.text)}
						</Text>
					)}
				</AnimatePresence>
			</TextWrap>
			{isExpandable && (
				<ShowMore onClick={() => setIsExpanded(!isExpanded)}>
					<Flex>
						<Chevron state={isExpanded ? 'up' : 'down'} /> {isExpanded ? strings[lang].showLess : strings[lang].showMore}
					</Flex>
				</ShowMore>
			)}
		</Section>
	)
}

export default WpAcfFlowtextBlock
